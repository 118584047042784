
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Error, { ErrorProps } from 'next/error';
const NotFoundPage = (props: Partial<ErrorProps>) => <Error {...props} statusCode={404}/>;
export default NotFoundPage;

    async function __Next_Translate__getStaticProps__193ab492cf3__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193ab492cf3__ as getStaticProps }
  